import React from "react"
import Layout from "../components/Layout"
import { StaticQuery, graphql } from 'gatsby'
import Landing from '../route/Landing/index'



export default function Home({ location }) {
  return <ContactLayout location={location} t="el" />
}

export const ContactLayout = ({location, t}) => {
  return (<Layout location={location} t="el">
    {/* <Hello t="default" /> */}
  </Layout>
  )
}